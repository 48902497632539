exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-gmal-qr-mfc-2025-index-js": () => import("./../../../src/pages/gmal/qr-mfc-2025/index.js" /* webpackChunkName: "component---src-pages-gmal-qr-mfc-2025-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-redeem-promotion-code-js": () => import("./../../../src/pages/redeem-promotion-code.js" /* webpackChunkName: "component---src-pages-redeem-promotion-code-js" */),
  "component---src-templates-game-game-js": () => import("./../../../src/templates/game/game.js" /* webpackChunkName: "component---src-templates-game-game-js" */),
  "component---src-templates-google-sheet-page-google-sheet-page-js": () => import("./../../../src/templates/google-sheet-page/google-sheet-page.js" /* webpackChunkName: "component---src-templates-google-sheet-page-google-sheet-page-js" */),
  "component---src-templates-store-details-page-store-details-page-js": () => import("./../../../src/templates/store-details-page/store-details-page.js" /* webpackChunkName: "component---src-templates-store-details-page-store-details-page-js" */),
  "component---src-templates-stores-page-stores-page-js": () => import("./../../../src/templates/stores-page/stores-page.js" /* webpackChunkName: "component---src-templates-stores-page-stores-page-js" */),
  "component---src-templates-stores-terms-and-conditions-page-stores-terms-and-conditions-page-js": () => import("./../../../src/templates/stores-terms-and-conditions-page/stores-terms-and-conditions-page.js" /* webpackChunkName: "component---src-templates-stores-terms-and-conditions-page-stores-terms-and-conditions-page-js" */)
}

